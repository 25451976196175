<template>
  <div class="footer">
      <img src="/img/salve.svg" alt="" class="mb-5 px-5 img-fluid">
  </div>
</template>

<script>
export default {

}
</script>
<style scoped>
    .footer {
        margin-top: 60px;
        margin-bottom: 80px;
    }
</style>