<template>
  <div id="home gy-5">
    <AdvancedSearch v-if="openModal" />
    <SearchResult />
    <Ficha />
    <ExportMessage  />
    <SaibaMais />
    <SaibaMaisMapa />
    <EspeciesImage />
    <div id="search">
      <SearchBar width="percent" />
      <button class="advanced_search mt-4" @click="openAdvancedSearch"><b>Pesquisa avançada</b></button>

      <div class="row w-100">
        <div class="col-md-6 col-8">
          <img class="img-fluid" id="main_image" :src="'/img/bola_' + bola + '.png'" :alt="creditPicsBola[bola]" :title="creditPicsBola[bola]" >
        </div>
        <div class="col-md-auto col-4" id="session_1_title">
          <img src="/img/salve.svg" alt="" class="mb-3 img-fluid d-md-block d-none">
          <h1 class="session_title">Risco de Extinção <br/>da Fauna Brasileira</h1>
        </div>
        <div class="col-12 ml-4" id="infos_balls">
        <loading-ajax  v-if="loadingHowToCite"></loading-ajax>
        <TotalNumbers :data="dataTotalNumbers" v-if="!loadingHowToCite" />
        </div>
      </div>

      <!-- Remoção temporaria solicitada pela equipe em 07/jun/2022 devido a regras de lançamento do Salve Publico-->
      <!--
      <div class="session_legend col-12 col-md-2">
        <p class="text-left">* O número apresentado, incluindo as subespécies, é resultado do processo contínuo de avaliação do risco de extinção da fauna brasileira. Porém, considerando as Portarias MMA N° 444 e 445/2014, temos 1.173 espécies oficialmente reconhecidas como ameaçadas de extinção.</p>
        <button class="text-center saibaMais" @click="saibaMais">Saiba mais</button>
      </div>
      -->
      <div class="session_legend col-12 col-md-2">
        <p class="text-left">* Não são contabilizadas as espécies em categorias de extinção (EX, RE e EW). A Lista Oficial inclui espécies em categoria EW.</p>

        <router-link to="/metodos-de-avaliacao" @click.native="saibaMaisEspeciesAmeacadas">
          <button class="text-center saibaMais">Saiba mais</button>
        </router-link>

      </div>



    </div>
    <div class="row mt-5">
      <div id="especies_destaque" class="col-12">
        <div class="division"></div>
        <div class="title">
          Espécies <br/>em destaque
        </div>
        <div class="session_gap"></div>
          <carousel :perPageCustom="[[0, 1], [768, 2], [1024,3]]"
          class="margem"
          :paginationEnabled="false"
          :navigationEnabled="true"
          :navigationClickTargetSize="0"
          navigationNextLabel="<i class='fas fa-arrow-right fa-2x'></i>"
          navigationPrevLabel="<i class='fas fa-arrow-left fa-2x'></i>">
            <slide class="p-2" v-for="item in itemsED" :key="item.recordId">
              <EspecieCard :item="item"/>
            </slide>
          </carousel>
      </div>
    </div>
    <div class="session_gap"></div>
    <div class="row align-items-center justify-content-center">
        <div class="col-12 stripe-evaluation text-center" title="Autor: Francesco Ungaro; Nome comum: Tartaruga Verde">
            <span class="tit1-w">Avaliação</span><br />
            <span class="tit2-w">em números</span>
        </div>
    </div>
    <div class="row" v-if="loadingEvaluatedCategories">
      <div class="col-12">
        <loading-ajax></loading-ajax>
      </div>
    </div>
    <div class="col-12" id="avaliacao_numeros" ref="topCharts" v-if="!loadingEvaluatedCategories">
      <div id="charts2" class="row chart d-flex align-items-center justify-content-center">
        <div class="col-12 mt-xl-5 px-0" v-show="activeBtn === 'btn1'">
          <carousel :perPage="1" @page-change="carouselChangePage" :navigationEnabled="true"
          :navigationClickTargetSize="0"
          class="margem"
          navigationNextLabel="<i class='fas fa-arrow-right fa-2x'></i>"
          navigationPrevLabel="<i class='fas fa-arrow-left fa-2x'></i>">
            <slide class="p-2" >
              <EvaluatedCategories :data="itemsGeral" />
            </slide>
            <slide class="p-2" >
              <EvaluatedCategories2 />
            </slide>
          </carousel>
        </div>
        <div class="col-12 graphic-maps" v-show="activeBtn === 'btn2'">
          <MapsM :visible="mapVisible" v-if="isMobile" :data="itemsGeral"/>
          <Maps :visible="mapVisible" v-if="!isMobile" :data="itemsGeral" />
        </div>
      </div>
        <div class="col-md-5 col-12 graphic-button mt-3">
            <button class="fill" @click="changeCharts('btn1')" :class="{active: activeBtn === 'btn1' }">Visão geral</button>
            <button class="fill" @click="changeCharts('btn2')" :class="{active: activeBtn === 'btn2' }">Visão por biomas</button>
        </div>
    </div>
    <div class="session_gap"></div>
    <div class="row align-items-center justify-content-center">
        <div class="col-12 stripe-category text-center" title="Autor: Erik Karits; Nome comum: Coruja-diabo">
            <span class="tit1-b">Espécies em</span><br />
            <span class="tit2-b">categorias de ameaça</span>
        </div>
    </div>
    <div class="col-12 mt-5" id="especie_ameaça">
      <div id="charts" class="row chart d-flex align-items-center justify-content-center">
        <div class="col-12 mx-0 px-0">
          <carousel :perPageCustom="[[0, 1], [768, 2], [1024,3]]"
          @page-change="carouselChangePage"
          :navigationEnabled="true"
          :navigationClickTargetSize="0" class="margem"
          navigationNextLabel="<i class='fas fa-arrow-right fa-2x'></i>"
          navigationPrevLabel="<i class='fas fa-arrow-left fa-2x'></i>">
            <slide class="p-2" v-for="(item, index, i) in itemsTGC" :key="item.label">
              <AnimalChart :item="item" :index="index" :key="index" :i="i" />
            </slide>
          </carousel>
        </div>
        <div class="col-12 mx-0 px-0" style="z-index:10;">
          <LegendAnimalChart />
        </div>
      </div>
    </div>
    <div class="session_gap"></div>
    <div class="session_gap"></div>
    <div class="col-12 realization row d-flex flex-column align-items-center justify-content-center">
      <h3><strong><b>Realização</b></strong></h3>
      <Realizacao />
    </div>
    <div class="session_gap"></div>
    <div class="session_gap"></div>
    <div class="col-12 partners row d-flex flex-column align-items-center justify-content-center">
      <h3><strong><b>Apoio</b></strong></h3>
      <Parceiros />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="textCit my-4 col-7 pt-5 align-self-center">
          <span class="citar-bold">Como citar:</span>
          <loading-ajax  v-if="loadingHowToCite"></loading-ajax>
          <p v-else v-html="comoCitar"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LegendAnimalChart from '@/web/components/LegendAnimalChart.vue'
import SearchBar from '@/components/searchComponents/SearchBar.vue'
import TotalNumbers from '@/web/components/TotalNumbers.vue'
const AdvancedSearch  = () => import(/* webpackChunkName: "search" */ '../../modais/AdvancedSearch.vue')
const SearchResult    = () => import(/* webpackChunkName: "search-result" */ '../../modais/SearchResult.vue')
const ExportMessage   = () => import(/* webpackChunkName: "search" */ '../../modais/ExportMessage.vue')
const Ficha           = () => import(/* webpackChunkName: "search" */ '../../modais/Ficha.vue')
const EspeciesImage   = () => import(/* webpackChunkName: "home-images-carousel" */ '@/modais/EspeciesImage.vue')
const SaibaMaisMapa   = () => import(/* webpackChunkName: "home-modal-saiba-mais-mapa" */ '@/web/modais/SaibaMaisMapa.vue')
import { Carousel, Slide } from 'vue-carousel'
import api from '@/services/api.js'
import barramento from '@/barramento'
import { isMobile } from 'mobile-device-detect';
import lazyLoadComponent  from '@/lazy-load-component.js'


export default {
  components: {
    EspecieCard: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-especie-card" */ '@/components/EspecieCard.vue'),
      loading: "loading-ajax",
    }),
    Carousel,
    Slide,
    AnimalChart: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-total-numbers" */ '@/web/components/AnimalChart.vue'),
      loading: "loading-ajax",
    }),
    TotalNumbers,
    EvaluatedCategories: lazyLoadComponent({
      componentFactory: () => import(/* webpackChunkName: "home-evaluated-categories" */ '@/web/components/EvaluatedCategories.vue'),
      loading: "loading-ajax",
    }),
    EvaluatedCategories2: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-evaluated-categories2" */ '@/web/components/EvaluatedCategories2.vue'),
      loading: "loading-ajax",
    }),
    LegendAnimalChart,
    SearchBar,
    AdvancedSearch,
    SearchResult,
    Ficha,
    Maps: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-biome-view" */ '@/web/components/Maps.vue'),
      loading: "loading-ajax",
    }),
    MapsM: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-biome-view-mobile" */ '@/mobile/components/Maps.vue'),
      loading: "loading-ajax",
    }),
    ExportMessage,
    SaibaMais: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-modal-saiba-mais" */ '@/web/modais/SaibaMais.vue'),
      loading: "loading-ajax",
    }),
    Parceiros: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-parceiros" */ '@/components/Parceiros.vue'),
      loading: "loading-ajax",
    }),
    SaibaMaisMapa,
    EspeciesImage,
    Realizacao: lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "home-realizacao" */ '@/components/Realizacao.vue'),
      loading: "loading-ajax",
    }),
  },
  data() {
    return {
      token:'',
      activeBtn:'btn1',
      carouselPage: 0,
      itemsED: [],
      itemsTGC: [],
      itemsGeral: [],
      openModal: false,
      comoCitar: '',
      bola: 1,
      creditPicsBola: [null
        ,'Nome comum: Arara-vermelha; Crédito: James Frid'
        ,'Nome comum: Lagartixa; Crédito: Afonso Meneses'
        ,'Nome comum: Urubu Rei; Crédito: Aure Vasconcelos'
        ,'Nome comum: Água Viva; Crédito: Camila Brasil'
        ,'Nome comum: Suçuarana; Crédito: Aure Vasconcelos'
        ,'Nome comum: Peixe-neón; Crédito: Áthila Bertoncini'
        ,'Nome comum: Peixe Anjo Anão; Crédito: Áthila Bertoncini'
        ,'Nome comum: Gavião Real; Crédito: Aure Vasconcelos'
        ,'Nome comum: Libélula; Crédito: Diogo Vilela'
        ,'Nome comum: Boto Vermelho; Crédito: Diogo Lagroteria'],
      mapVisible: false,
      loadingHowToCite:true,
      loadingEvaluatedCategories:true,
      isMobile: isMobile ? true : false,
      dataTotalNumbers:[],
      creditPicsPhoto: ['creditPicsBola'],
    }
  },
  watch: {
    /*
    async token(){
        if( this.token ){
          let response = await api.get('/totalNumbers?reCaptchaToken=' + this.token);
          if (response.data.status != 200) {
            alert(response.data.msg);
          } else {
            let data = response.data.data;
            this.dataTotalNumbers = data
            this.comoCitar = data[4]['howToCite']
            barramento.$emit('homeTotalNumbersApiLoaded', data)
          }
          this.loadingHowToCite = false
        }
    },*/
    activeBtn(){
      if ( this.activeBtn == 'btn2'){
        this.mapVisible = true
      }
    }
  },

  created() {

    this.$on('openModalAdvancedSearch', () => {
      this.openModal = true
      this.openAdvancedSearch()
    })
    if(isNaN(localStorage.ballImage)){
      this.bola = 1
      localStorage.setItem('ballImage', '1');
    } else {
      this.bola = localStorage.ballImage
    }
    if (Number.parseInt(localStorage.ballImage) >= 10){
      localStorage.setItem('ballImage', '1');
    } else {
      var value = Number.parseInt(localStorage.ballImage) + 1
      localStorage.setItem('ballImage', `${ value }`)
    }

    this.$nextTick(() => {
      this.onReCaptchaReady()
    })

    /*
    // google reCaptcha
    this.$nextTick(() => {
      window.grecaptcha.ready( async () => {
        this.token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
        this.onReCaptchaReady()
      });
    })
    */

    //var t = this
    /*
     await window.grecaptcha.ready( async function () {
       let token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
       let response = await api.get('http://localhost:8090/salve-api/public/totalNumbers?reCaptchaToken=' + token);
       if (response.data.status != 200) {
         alert(response.data.msg);
       } else {
         let data = response.data.data;
         t.dataTotalNumbers = data
         t.comoCitar = data[4]['howToCite']
         barramento.$emit('homeTotalNumbersApiLoaded', data)
       }
       t.loadingHowToCite = false
   */
      /*
       window.$.get('http://localhost:8090/salve-api/public/totalNumbers?reCaptchaToken=' + token, function(data,status){
         if( status !== 'success' ){
           alert( data.msg );
         } else {
           data = data.data
           t.dataTotalNumbers = data
           t.comoCitar = data[4]['howToCite']
           barramento.$emit('homeTotalNumbersApiLoaded', data)
           t.loadingHowToCite = false

         }
       });*/
      //let response = await api.get('/totalNumbers?reCaptchaToken=' + token);
      //console.log( response.data )
      //barramento.$emit('homeTotalNumbersApiLoaded', response.data);
         /* let data = response.data.data
          if( response.data.status != 200 ){
              alert( response.data.msg );
          } else {
            if( data ) {
              t.dataTotalNumbers = data
              t.comoCitar = data[4]['howToCite']
              barramento.$emit('homeTotalNumbersApiLoaded', data)
            }
          }
        }).finally(() => {
          t.loadingHowToCite = false
        });*/

        //
        /*
        await api.get('/highlightedSpecies').then(response => (
            t.itemsED = response.data.data
        ));

        //
        await api.get('/graphThreatenedGroupsCategories').then(response => (
            t.itemsTGC = response.data.data
        ));

        //
        await api.get('/graphEvaluatedCategories').then(response => {
          t.itemsGeral = t.normalizeDataEvaluatedCategories(response.data.data)
        }).finally(()=>{t.loadingEvaluatedCategories=false});
        */
   //   });


/*
    await api.get('/highlightedSpecies').then(response => (
        t.itemsED = response.data.data
    ))

    await api.get('/graphThreatenedGroupsCategories').then(response => (
        t.itemsTGC = response.data.data
    ))

    await api.get('/graphEvaluatedCategories').then(response => {
      t.itemsGeral = this.normalizeDataEvaluatedCategories(response.data.data)
    }).finally(()=>{this.loadingEvaluatedCategories=false})
    */
  },
  methods: {
    async onReCaptchaReady(){
      /*if( !this.token ){
        this.loadingHowToCite = false
        this.loadingEvaluatedCategories=false
        return;
      }*/

      /*
      let response = await api.get('/totalNumbers?reCaptchaToken=' + this.token);
      */
      //api.get('/totalNumbers?reCaptchaToken=' + this.token).then(response => {
      await api.get('/totalNumbers').then(response => {
        if (response.data.status != 200) {
          alert(response.data.msg);
        } else {
          let data = response.data.data;
          this.dataTotalNumbers = data
          this.comoCitar = data[4]['howToCite']
          barramento.$emit('homeTotalNumbersApiLoaded', data)
        }
      }).finally(()=>{this.loadingHowToCite = false})


      /*
      // assim tambem funciona
      window.grecaptcha.ready( async () => {
        this.token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
        //
        api.get('/highlightedSpecies?reCaptchaToken=' + this.token).then(response => (
            this.itemsED = response.data.data
         ));
      });
      */
      await api.get('/highlightedSpecies').then(response => (
            this.itemsED = response.data.data
      ));


      //
      await api.get('/graphThreatenedGroupsCategories').then(response => (
          this.itemsTGC = response.data.data
      ));

      //
      await api.get('/graphEvaluatedCategories').then(response => {
        this.itemsGeral = this.normalizeDataEvaluatedCategories(response.data.data)
      }).finally(()=>{this.loadingEvaluatedCategories=false});

    },


    normalizeDataEvaluatedCategories(dataApi){
      /** Valores pré-definidos caso API não retorne determinada categoria e seus totais*/
      let objCatEmpty = []
      objCatEmpty[0] = {category:"Extinta (EX)",categoryCode:"EX",percent:0,threatened:false,total:0};
      objCatEmpty[1] = {category:"Extinta na Natureza (EW)",categoryCode:"EW",percent:0,threatened:false,total:0};
      objCatEmpty[2] = {category:"Regionalmente Extinta (RE)",categoryCode:"RE",percent:0,threatened:false,total:0};
      objCatEmpty[3] = {category:"Criticamente em Perigo (CR)",categoryCode:"CR",percent:0,threatened:false,total:0};
      objCatEmpty[4] = {category:"Em Perigo (EN)",categoryCode:"EN",percent:0,threatened:false,total:0};
      objCatEmpty[5] = {category:"Vulnerável (VU)",categoryCode:"VU",percent:0,threatened:false,total:0};
      objCatEmpty[6] = {category:"Quase Ameaçada (NT)",categoryCode:"NT",percent:0,threatened:false,total:0};
      objCatEmpty[7] = {category:"Menos Preocupante (LC)",categoryCode:"LC",percent:0,threatened:false,total:0};
      objCatEmpty[8] = {category:"Dados Insuficientes (DD)",categoryCode:"DD",percent:0,threatened:false,total:0};
      objCatEmpty[9] = {category:"Não Aplicável (NA)",categoryCode:"NA",percent:0,threatened:false,total:0};

      // Novo array de dados que será reordenado e populado caso necessário
      let newData = []

      /** Ordena as categorias obtidas para a posição esperada*/
      dataApi.map(function(cat){
        if(cat.categoryCode){
          switch(cat.categoryCode){
            case 'EX':
              newData[0] = cat; break;
            case 'EW':
              newData[1] = cat; break;
            case 'RE':
              newData[2] = cat; break;
            case 'CR':
              newData[3] = cat; break;
            case 'EN':
              newData[4] = cat; break;
            case 'VU':
              newData[5] = cat; break;
            case 'NT':
              newData[6] = cat; break;
            case 'LC':
              newData[7] = cat; break;
            case 'DD':
              newData[8] = cat; break;
            case 'NA':
              newData[9] = cat; break;
          }
        }
      });
      /** Aplica volaroes padrão(zerado) caso determinada categoria não seja retornada pela API*/
      for( var i = 0; i <= 9; i++){
        if(undefined == newData[i]){
          newData[i] = objCatEmpty[i]
        }
      }
      return newData
    },
    carouselChangePage(e) {
      this.carouselPage = e
    },
    openAdvancedSearch() {
      if (this.openModal == true) {
        barramento.$emit('openModal')
      }
      this.openModal = true
    },
    saibaMais() {
      barramento.$emit('modal_saiba_mais')
    },
    changeCharts(btn) {
      this.activeBtn = btn
      var element = this.$refs['topCharts'];
      var top = element.offsetTop;

      window.scrollTo(0, top - 100);
    },
    saibaMaisEspeciesAmeacadas() {
      window.scrollTo(0, 0);
    },

  }
}

</script>

<style lang="scss">
  .textCit {
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    color: #646464;
  }
  .textCit a{
    font-weight: bold;
    color: #646464;
  }
  .citar-bold{
    font-weight: bold;
    font-size: x-large;
  }
  .graphic-button {
    margin-top: -60px;
    margin-bottom: 60px;
    margin-left: 60px;

    button {
        margin-top: 35px;
        margin-left: 7px;
        margin-right: 7px;
        width: 220px;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        border-radius: 20px;
        font-weight: bold;
        font-size: 15px;
    }

    .active {
        background-color: #32B3F9 !important;
        color: #FFFFFF !important;
        border: none;
    }
    .fill {
        background-color: #C8C8C8;
        color: #282828;
        border: none;
    }
  }

  .parcerias {
    margin-top: 70px;
  }
  .card {
    border:none !important;
  }
  #home {
    margin-top: -2vw;
  }
  // #main_image {
  //   position: absolute;
  //   left: 9vw;
  //   z-index: -100;
  // }
  .advanced_search {
    background: none;
    border: none;
    color: #282828;
    float: right;
    margin-right: 27vw;
    margin-top: 5px;
    text-decoration: none;
    font-size: 13px;
  }
  .advanced_search:hover {
    color: #282828 !important;
    border-bottom: 2px solid #BEE45B;
  }
  #infos_balls {
    width: 75vw;
    margin-top: -15vw;
    display: inline-block;
    padding-left: 15rem!important;
    padding-right: 15rem!important;
  }
  #session_1_title {
    float: right;
    margin-top: 6rem;
    margin-right: -10vw;
  }
  .session_title {
    text-align: right;
    font-family: Roboto;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.17;
    color: #282828;
  }
  .session_legend {
    height: 8vw;
    margin: auto;
    margin-right: 15vw;
    margin-top: -10vw;
    font-size: 11px;
    line-height: 1.4;
    color: #646464;
  }
  .session_legend button {
    width: 8vw;
    height: 2.5vw;
    border-radius: 17px;
    border: none;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #282828;
    background-color: #e2f5a1;
  }
  .session_gap {
    height: 4vw;
  }
  .division {
    width: 50px;
    height: 3px;
    border-radius: 4px;
    margin: auto;
    margin-bottom: 25px;
    background-color: black;
  }
  .title {
    width: 15vw;
    margin: auto;
    font-family: Roboto;
    font-size: 1.28rem !important;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #282828;
  }
  .white_title {
    color: #ffffff !important;
  }
  .white_division {
    background-color: #ffffff !important;
  }
  .center_title {
    padding-top: 115px;
  }
  .carousel {
     width: 100%;
     height: 320px;
  }
  .animal_session {
    width: 100%;
    height: 300px;
  }
  .blue_session {
    background-image: linear-gradient(to bottom, rgba(32, 139, 206, 0.18) 25%, #208bce 100%);
  }
  .green_session {
    background-image: linear-gradient(to bottom, rgba(204, 222, 141, 0.18) 25%, #CCDE8D 100%);
  }
  .graphicBall {
    margin-top: -3vw;
    margin-bottom: -3vw;
  }
  .chart {
    margin-top: -7vw;
  }
  .margem{
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .stripe-evaluation{
      background-image: url(/img/animals/tartaruga.png);
      background-size: cover;
      height: 350px;
      padding-top: 10rem;
  }
  .stripe-category{
      background-image: url(/img/animals/coruja-full.png);
      background-size: cover;
      height: 350px;
      padding-top: 10rem;
  }
  .tit1-w {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      color: white;
      border-top: 3px solid white;
      padding-top: 1.5rem;
  }
  .tit2-w {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      color: white;
  }
  .tit1-b {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      color: #191919;
      border-top: 3px solid #191919;
      padding-top: 1.5rem;
  }
  .tit2-b {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      color: #191919;
  }
@media screen and (min-width: 600px) and (max-width: 1400px) {
  #session_1_title {
    margin-right: -27vw !important;
  }
  #infos_balls {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  .totalNumbers {
    margin-top: -2rem;
  }
  .session_legend {
    margin-top: -15vw;
  }
  .session_legend button {
    width: 5rem;
    height: 2rem;
  }
  .graphic-button {
    margin-left: 0px;
  }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
  .graphicBall {
      margin-top: -19vw;
  }
  .table-graphic {
    margin-top: -10rem;
  }
  .table-home2 .table td {
    padding-right: 10px;
    padding-left: 20px;
  }
  .table-home {
    margin-top: .5rem;
  }
}
@media screen and (max-width: 600px) {
.graphic-button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  button {
    width: 10rem;
    margin-left: .5rem;
    margin-right: .5rem;
    height: 35px;
    font-size: 0.75rem;
  }
}
.parcerias {
  max-width: 90%;
}
.parc-2 {
  width: 50%;
}
#main_image {
  margin-left: .5rem;
  position: unset;
}
.advanced_search {
  margin-right: 3vw;
  margin-top: 1rem;
}
.advanced_search:hover {
  color: #bee45b;
}
#infos_balls {
  margin-top: -4rem;
  padding-left: 0!important;
  padding-right: 0!important;
}
#session_1_title {
  width: 21vw;
  margin-top: 15vw;
}
.session_title {
  font-size: 1.2rem;
  font-weight: bold;
}
.legend {
  font-size: 0.7rem;
  }
.saibaMais {
  border-radius: 17px;
  border: none;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.33;
  color: #282828;
  margin: auto auto auto 0;
  background-color: #e2f5a1;
  padding: 9px 19px 9px 20px;
}
.session_legend {
  margin-top: -7rem;
  margin-bottom: 7rem;
}
.session_legend button {
  width: auto;
  height: auto;
}
.title {
  margin: auto;
  width: auto;
  font-size: 1.1rem;
  line-height: 1.1;
}
.sub-tit {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.14;
  text-align: center;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}

  .layer {
    background-color: #eee;
    background: linear-gradient(
      to left,
      rgba(32, 139, 206, 0.18) 0%,
      #208bce 200px
    ); /* W3C */
    background: -moz-linear-gradient(
      to left,
      rgba(32, 139, 206, 0.18) 0%,
      #208bce 200px
    ); /* FF3.6+ */
    background: -webkit-linear-gradient(
      to left,
      rgba(32, 139, 206, 0.18) 0%,
      #208bce 200px
    ); /* Chrome10+,Safari5.1+ */
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 200px;
    background-image: url(/img/animals/tartaruga-mobile.jpg);
  }
  .division-white {
    width: 50px;
    height: 3px;
    border-radius: 4px;
    margin: auto;
    margin-bottom: 25px;
    background-color: #fff;
  }
  .margem{
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .stripe-evaluation{
    height: 150px;
    padding-top: 40px;
  }
  .stripe-category{
    height: 150px;
    padding-top: 40px;
  }
  .tit1 {
    font-size: 1.3rem;
    padding-top: .5rem;
  }
  .tit2 {
    font-size: 1.3rem;
  }
  .tit1-b {
    font-size: 1.3rem;
    padding-top: .5rem;
    margin-left: 6rem;
  }
  .tit2-b {
    font-size: 1.3rem;
    margin-left: 6rem;
  }
}
// CELULARES ANTIGOS
@media screen and (min-width: 300px) and (max-width: 374px) {
  #infos_balls {
    margin-top: 0rem;
  }
  .session_legend {
    margin-top: -5rem;
    margin-bottom: 7rem;
  }
  .tit1 {
    font-size: 1rem;
  }
  .tit2 {
    font-size: 1rem;
  }
  .tit1-b {
    font-size: 1rem;

  }
  .tit2-b {
    font-size: 1rem;
  }
}
// EXTRA LARGE SCREENS
@media screen and (min-width: 1700px){
  .chart {
    margin-top: -5vw;
  }
  .advanced_search {
    margin-right: 33vw;
  }
}
</style>
