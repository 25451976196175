<template>

    <b-modal no-close-on-backdrop no-close-on-esc id="modal_como_citar" size="md"  :hide-header="true" hide-footer>
        <div class="close-button float-right">
            <button size="lg" @click="close()" title="Fechar">
              <i class="text-danger fa fa-times fa-lg"></i>
            </button>
            <br><br><br>
        </div>
        <div class="card h-100 py-1">
            <h4><b>Como Citar</b></h4>
            <div class="session-gap"></div>
            <div class="session-gap"></div>
            <div class="textCitar"  v-html="text"></div>

        </div>
    </b-modal>
</template>

<script>
import api from '@/services/api.js'
import barramento from '@/barramento'

export default {
    data() {
        return {
            text: ''
        }
    },
    async created() {
        var t = this
        await api.get('/totalNumbers').then(response => (
            t.text = response.data.data[4]['howToCite']
        ))

        barramento.$on('modal_como_citar', () => {

            this.$bvModal.show('modal_como_citar')
        })
    },
    methods: {

        close(){
             this.$bvModal.hide('modal_como_citar')
        }

    }
}
</script>

<style lang="scss">

    #modal_como_citar {
        .modal-dialog .modal-content {
            border-radius: 15px !important;
            padding: 30px !important;
            background-color: #E2F5A1;
        }
        .card {
            background-color: #E2F5A1;
            margin-top: 10px;
        }
        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            text-decoration: none;
        }
        .textCitar {
            font-size: 12px;
            color: #646464;
        }
        .session-gap {
            height: 15px;
        }

    }
</style>